import {
  UPDATE_COUPON_LIST_REQUEST,
  GET_COUPON_LIST,
  GET_COUPON_LIST_LOADING,
  INITIAL_STATE,
  DASHBOARD_OVER_VIEW
} from "../actions/types"

const initialState = {
  couponList: [],
  couponListRequest: {
    status: "",
    campStartDate: "",
    campEndDate: "",
    campEndStartDate: "",
    campEndEndDate: ""
  },
  loading: false,
  dashboardOverviewData: ""
}

const coupons = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case DASHBOARD_OVER_VIEW:
      return {
        ...state,
        dashboardOverviewData: payload
      }
    case INITIAL_STATE:
      return {
        ...state,
        couponListRequest: initialState.couponListRequest
      }
    case GET_COUPON_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_COUPON_LIST_REQUEST:
      return {
        ...state,
        couponListRequest: {
          ...state.couponListRequest,
          [payload.name]: payload.value
        }
      }
    case GET_COUPON_LIST:
      return {
        ...state,
        couponList: payload
      }
    default:
      return state
  }
}

export default coupons